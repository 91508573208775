import {useState, useEffect, useContext} from 'react'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import Meta from '../types/models/meta'
import {Referral} from "../types/models/referral";
import {getReferralsAction} from "../actions/referral";
import {FilterContext} from "../providers/FilterProvider";


const useReferral = () =>
{
    const { useFilter, usePagination } = useContext(FilterContext)
    const { getFilter } = useFilter
    const { getPagination } = usePagination
    const selector = useAppSelector((state) => state.referrals)
    const dispatch = useAppDispatch()
    const [meta, setMeta] = useState<Meta|undefined>(selector?.meta)
    const [loading, setLoading] = useState(selector.loading)
    const [referrals, setReferrals] = useState<Referral[]>()
    const [errors, setErrors] = useState(selector.errors)
    const [success, setSuccess] = useState(selector.success)

    useEffect(() => {
        setReferrals(selector?.data)
        setMeta(selector?.meta)
        setLoading(selector.loading)
        setErrors(selector.errors)
        setSuccess(selector.success)
    }, [selector])

    const getReferrals = (page?: number) =>
    {
        const name = getFilter('searchField') === 'name' ? getFilter('searchValue') : undefined
        dispatch(getReferralsAction(name, page ?? getPagination()))
    }

    return {
        getReferrals,
        loading,
        referrals,
        meta,
        errors,
        success
    }
}

export default useReferral

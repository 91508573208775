import { Action, ActionType, initialState, State } from '../types/actions/analytics';

const referralReducer = (state: State = initialState, action: Action): State =>
{
    switch(action.type)
    {
        case ActionType.ANALYTICS_ERROR:
        case ActionType.ANALYTICS_LOADING:
        case ActionType.ANALYTICS_GETS:
            return {...state, ...action};
        default:
            return state;
    }
}

export default referralReducer;

import {useEffect} from 'react'
import {Helmet} from 'react-helmet'

import useVPNBot from '../../../hooks/useVPNBot'
import Spinner from '../../Spinner'
import {AnalyticsBarChart} from "../../Analytics/AnalyticsBarChart";
import {AnalyticsTariffCharts} from "../../Analytics/AnalyticsTariffCharts";


const AnalyticsTab = () => {

    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const {useAnalytics} = useVPNBot()
    const {getAnalytics, analytics, loading} = useAnalytics

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        getAnalytics()
    }, [])

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    const renderContent = () => {
        if (loading) {
            return (
                <div className='flex justify-items-center items-center flex-col w-full'>
                    <Spinner/>
                </div>
            )
        }

        if (!analytics) {
            return (
                <div className='flex justify-items-center items-center flex-col w-full'>
                    No data
                </div>
            )
        }

        return (
            <div className="flex flex-col gap-16">
                <span className="font-semibold text-lg">
                    Total active users: {analytics.active_clients}
                </span>
                <AnalyticsBarChart title="New clients" statistics={analytics.new_clients}/>
                <AnalyticsBarChart title="First time (paid)" statistics={analytics.first_time_paid_clients}/>
                <AnalyticsBarChart title="Reconnected (paid)" statistics={analytics.repeat_paid_clients}/>
                <AnalyticsTariffCharts statistics={analytics.tariffs_statistic}/>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Analytics - VPNBot Admin</title>
            </Helmet>
            {renderContent()}
        </>
    )
}

export default AnalyticsTab

import { useState, useEffect } from 'react'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import {Analytics} from "../types/models/analytics";
import {getAnalyticsAction} from "../actions/analytics";


const useAnalytics = () =>
{
    const selector = useAppSelector((state) => state.analytics)
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(selector.loading)
    const [analytics, setAnalytics] = useState<Analytics>()
    const [errors, setErrors] = useState(selector.errors)
    const [success, setSuccess] = useState(selector.success)

    useEffect(() => {
        setAnalytics(selector?.data)
        setLoading(selector.loading)
        setErrors(selector.errors)
        setSuccess(selector.success)
    }, [selector])

    const getAnalytics = () =>
    {
        dispatch(getAnalyticsAction())
    }

    return {
        getAnalytics,
        loading,
        analytics,
        errors,
        success
    }
}

export default useAnalytics

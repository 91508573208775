export enum AnalyticsDateRange {
    WEEK = "week",
    MONTH = "month",
    YEAR = "year",
}

export interface AnalyticsWeeklyData {
    Sunday: number;
    Monday: number;
    Tuesday: number;
    Wednesday: number;
    Thursday: number;
    Friday: number;
    Saturday: number;
}

export interface AnalyticsMonthlyData {
    [day: number]: number;
}

export interface AnalyticsYearlyData {
    January: number;
    February: number;
    March: number;
    April: number;
    May: number;
    June: number;
    July: number;
    August: number;
    September: number;
    October: number;
    November: number;
    December: number;
}

export interface AnalyticsClientStatistics {
    [AnalyticsDateRange.WEEK]: AnalyticsWeeklyData;
    [AnalyticsDateRange.MONTH]: AnalyticsMonthlyData;
    [AnalyticsDateRange.YEAR]: AnalyticsYearlyData;
}

export interface AnalyticsTariff {
    title: string;
    count: number;
    price: number;
}

export enum AnalyticsTariffStatisticSection {
    FREE = 'free',
    MONTH = 'month',
    HALF_YEAR = 'half_year',
    YEAR = 'year',
}

export interface AnalyticsTariffStatistic {
    [AnalyticsTariffStatisticSection.FREE]: AnalyticsTariff;
    [AnalyticsTariffStatisticSection.MONTH]: AnalyticsTariff;
    [AnalyticsTariffStatisticSection.HALF_YEAR]: AnalyticsTariff;
    [AnalyticsTariffStatisticSection.YEAR]: AnalyticsTariff;
}

export interface AnalyticsTariffStatistics {
    [AnalyticsDateRange.WEEK]: AnalyticsTariffStatistic
    [AnalyticsDateRange.MONTH]: AnalyticsTariffStatistic
    [AnalyticsDateRange.YEAR]: AnalyticsTariffStatistic
}

export interface Analytics {
    active_clients: number;
    new_clients: AnalyticsClientStatistics;
    first_time_paid_clients: AnalyticsClientStatistics;
    repeat_paid_clients: AnalyticsClientStatistics;
    tariffs_statistic: AnalyticsTariffStatistics;
}

import { Action, ActionType, initialState, State } from '../types/actions/referral';

const referralReducer = (state: State = initialState, action: Action): State =>
{
    switch(action.type)
    {
        case ActionType.REFERRAL_ERROR:
        case ActionType.REFERRAL_LOADING:
        case ActionType.REFERRAL_GETS:
            return {...state, ...action};
        default:
            return state;
    }
}

export default referralReducer;

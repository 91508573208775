import {request} from './index'
import TokenConfig from './token'

const getReferrals = (name?: string, page?: number) => {
    return request.get('referrals', {
        headers: TokenConfig().headers,
        params: {
            page: page,
            name: name,
        }
    })
}

export { getReferrals };

import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import {Analytics} from "../models/analytics";

export enum ActionType{
    ANALYTICS_LOADING   = 'ANALYTICS_LOADING',
    ANALYTICS_GETS      = 'ANALYTICS_GETS',
    ANALYTICS_ERROR     = 'ANALYTICS_ERROR'
}

interface actionLoading
{
    type: ActionType.ANALYTICS_LOADING
}

interface actionGets
{
    type: ActionType.ANALYTICS_GETS
}

interface actionError
{
    type: ActionType.ANALYTICS_ERROR
}

export type Action = actionLoading | actionGets | actionError;

export type State = reducerState & apiResponse<Analytics>;

export const initialState: State =
{
    type: undefined,
    data: undefined,
    loading: false,
    success: true,
    errors: [],
}

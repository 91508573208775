
import { createContext } from 'react'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import useItem from '../../hooks/useItem'
import useKey from '../../hooks/useKey'
import usePagination from '../../hooks/usePagination'
import usePromocode from '../../hooks/usePromocode'
import usePurchase from '../../hooks/usePurchase'
import useTab from '../../hooks/useTab'
import useUser from '../../hooks/useUser'
import useServer from '../../hooks/useServer'
import useCountry from '../../hooks/useCountry'
import usePage from '../../hooks/usePage'
import useClient from '../../hooks/useClient'
import useTariff from '../../hooks/useTariff'
import useReferral from "../../hooks/useReferral"
import useAnalytics from "../../hooks/useAnalytics";

interface IHookProps
{
    useBreadcrumbs: ReturnType<typeof useBreadcrumbs>,
    useClient: ReturnType<typeof useClient>,
    useReferral: ReturnType<typeof useReferral>,
    useAnalytics: ReturnType<typeof useAnalytics>,
    useCountry: ReturnType<typeof useCountry>,
    useItem: ReturnType<typeof useItem>,
    useKey: ReturnType<typeof useKey>,
    usePage: ReturnType<typeof usePage>,
    usePagination: ReturnType<typeof usePagination>,
    usePromocode: ReturnType<typeof usePromocode>,
    usePurchase: ReturnType<typeof usePurchase>,
    useTab: ReturnType<typeof useTab>,
    useServer: ReturnType<typeof useServer>
    useUser: ReturnType<typeof useUser>,
    useTariff: ReturnType<typeof useTariff>
}

const VPNBotContext = createContext({} as IHookProps);
const VPNBotProvider = ({ children }: any) => {
    const hookList = {
        useBreadcrumbs: useBreadcrumbs(),
        useClient: useClient(),
        useReferral: useReferral(),
        useAnalytics: useAnalytics(),
        useCountry: useCountry(),
        useItem: useItem(),
        useKey: useKey(),
        usePage: usePage(),
        usePagination: usePagination(),
        usePromocode: usePromocode(),
        usePurchase: usePurchase(),
        useServer: useServer(),
        useTab: useTab(),
        useUser: useUser(),
        useTariff: useTariff()
    }
    return <VPNBotContext.Provider value={hookList} children={children} />
}

export { VPNBotProvider, VPNBotContext }

import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Table } from "antd"
import { FilterContext } from '../../../providers/FilterProvider'
import Tariff from '../../../types/models/tariff'
import useVPNBot from '../../../hooks/useVPNBot'
import Spinner from '../../Spinner'

import './index.scss'

const PurchasesTab = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { usePagination } = useContext(FilterContext)
    const { useTariff } = useVPNBot()
    const { getTariffs, tariffs, loading, meta } = useTariff
    const { getPagination, setPagination } = usePagination
    const [ dataSource, setDataSource ] = useState<any[]>()

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];
        tariffs?.map((tariff: Tariff, index: number) => {
            newDataSource.push({
                key:            index,
                id:             tariff.id,
                uuid:           tariff.uuid,
                purchase_date:  tariff.created_at,
                username:       tariff.client.name,
                item:           tariff.item.name,
                cost:           tariff?.purchase?.total_price ?? '-',
                promocode:      tariff?.purchase?.promocode?.name
            })
        })
        setDataSource(newDataSource)
    }, [tariffs])


    useEffect(getTariffs, [])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const handleChange = (pagination: any, filters: any, sorter: any) =>
    {
        setPagination(pagination.current)
        getTariffs(pagination.current)
    }

    //-------------------------------------------------------------------------------
    //                                 TABLE COLUMNS
    //-------------------------------------------------------------------------------

    const columns = [
        {
            title: 'Payment Date',
            dataIndex: 'purchase_date',
            key: 'purchase_date',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
        },
        {
            title: 'Promocode',
            dataIndex: 'promocode',
            key: 'promocode',
        },
    ];

    //-------------------------------------------------------------------------------
    //                                 RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Purchases - VPNBot Admin</title>
            </Helmet>
            {
                !loading ?
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        onChange={handleChange}
                        pagination={meta?.last_page === 1 ? false : {total: meta?.total, pageSize: meta?.per_page, current: getPagination()}}
                        className={"promocodes__tab"}
                    />
                :
                    <div className='flex justify-items-center items-center flex-col w-full'>
                        <Spinner/>
                    </div>
            }
        </>
    )
}

export default PurchasesTab

import { AppThunk } from '../store'
import { ActionType } from '../types/actions/referral'
import { logoutUser } from './user'
import {Referral} from "../types/models/referral";
import {getReferrals} from "../api/referralAPI";


const getReferralsAction = (name?: string, page?: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.REFERRAL_LOADING
    })
    getReferrals(name, page).then((response) => {
        const referralsResponse = response.data as Referral[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.REFERRAL_GETS,
            ...referralsResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        if (exception.response.status === 401)
            dispatch(logoutUser())
        else
            dispatch({
                loading: false,
                type: ActionType.REFERRAL_ERROR,
                ...exception.response.data
            })
    });
}

export { getReferralsAction }

